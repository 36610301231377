import {required, minLength, helpers} from "vuelidate/lib/validators";
const login = helpers.regex('login', /^[a-zA-z0-9]+$/);

export default {
    validations:{
        payload: {
            first_name: {
                required,
                minLength: minLength(3)
            },
            last_name: {
                required,
                minLength: minLength(3)
            },
            login: {
                required,
                minLength: minLength(3),
                login
            },
            password: {
                minLength: minLength(4)
            },
            // sources_groups_ids: {
            //     required
            // },
            // service_groups_ids: {
            //     required
            // }
        },

    },
}