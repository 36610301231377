<template>
  <div class="container user-edit">
    <div v-if="isLoading" class="d-flex justify-content-center">
      <b-spinner/>
    </div>

    <user-form ref="childForm"
               action="edit"
               v-show="!isLoading"
               :initial-data="initialData"/>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import filters from "@/mixins/filters";
import validateUser from "@/mixins/validateUser";
import _ from "underscore";
import UserForm from "@/components/users/UserForm.vue";
export default {
  name: "UserEdit",
  components: {UserForm},
  mixins: [filters, validateUser],
  data() {
    return {
      isSubmitted: false,
      isLoading: false,
      userGroupsLoading: false,
      userServicesLoading: false,
      settings: {
        action: "update",
      },
      labelPosition: 'on-border',
      initialAccount: null,
      initialData: {},
      sources_groups: [],
      services_groups: [],
      loaded_sources_groups: true,
      loaded_services_groups: true,
      selectRole: null,
      payload: {},
      childUsers: []
    }
  },
  computed: {
    ...mapGetters('users', ['roles', 'permissions']),
    ...mapGetters('source_groups', ['groups']),
    ...mapGetters({service_groups: 'service_groups/groups'}),
    ...mapGetters('auth', ['user']),
    isMe() {
      return this.payload.id === this.user.id
    }
  },
  methods: {
    submitForm(action) {
      this.isSubmitted = true;
      if (!this.$v.payload.$invalid) {
        if (action === "update") {
          this.isLoading = true;
          delete this.payload.login
          if (!this.payload.password) {
            delete this.payload.password;
          }
          const sendPayload = (() => {
            if(this.isMe) {
              return {
                id: this.payload.id,
                password: this.payload.password
              }
            }
            return this.payload;
          })()
          this.$store.dispatch('users/updateUser', sendPayload)
              .then(() => {
                this.isLoading = false;
                this.$router.push({ name: 'Users' });
              })
              .catch(() => {
                this.isLoading = false
              });
        } else {
          this.$store.dispatch('users/createUser', this.payload);
        }
      }
    },
    loadSourcesGroups(parentId) {
      this.userGroupsLoading = true;
      this.$store.dispatch('source_groups/getGroupList', {parentId})
        .catch(() => {
          this.loaded_sources_groups = false;
        })
        .finally(() => {
          this.userGroupsLoading = false;
        })
    },
    loadUserServices(parentId) {
      this.userServicesLoading = true;
      this.$store.dispatch('service_groups/getGroupList', {parentId})
          .catch(() => {
            this.loaded_services_groups = false;
          })
          .finally(() => {
            this.userServicesLoading = false;
          })
    },
    setAccount(account) {
      this.payload.account = account;
    },
    setInitialData(data) {
      return new Promise((resolve) => {
        this.initialData = data;
        resolve()
      });

    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    }
  },
  mounted() {
    this.$store.dispatch('contacts/changeLoading', true);
    this.startLoading();
    this.$store.dispatch('users/getUser', this.$route.params.id).then( async (data) => {
      await this.setInitialData(data);
      await this.stopLoading();
      await this.$refs.childForm.mountAction();
    });
    this.$store.dispatch('users/getUsersList', {}).then((data) => {
      const childUsers = data.filter(item => {
        return item.parent_id === parseInt(this.$route.params.id) && item.account.is_active ;
      })
      const nextLevelChildren = data.filter(item => {
        return childUsers.map(child => child.id).includes(item.parent_id) && item.account.is_active;
      });
      const childrenCount = [...childUsers, ...nextLevelChildren].length
      this.$refs.childForm.setChildrenCount(childrenCount || 1);
    }).catch(() => {
      this.$refs.childForm.setChildrenCount(1);
    })
  },
  watch: {
    selectRole(val) {
      if(!_.isUndefined(val)) {
        this.payload.roles = [val];
      } else {
        delete this.payload.roles;
      }
    }
  }
}
</script>

<style scoped>
.user-edit {
  padding-top: 40px;
}
.custom-control{
  padding: 0;
}
.control-label{
  padding-left: 5px;
  padding-right: 5px;
}
</style>